import React, {useEffect, useState} from 'react';

import {useControllableState, useEvent} from 'tamagui';

import {
  AppEvents,
  Pressable,
  ShellTrans,
  Typography,
  useAppEventHub,
  useAppletsList,
  useAppStructure,
  useAppTheme,
  XStack,
  YStack,
  BidirectionalIcon,
} from '@medad-sep/core';

import {useMenuItems} from './hooks/useMenuItems';

interface Props {
  highlightCurrentPage?: boolean;
}

export const SideBarMenu = ({highlightCurrentPage}: Props) => {
  const appStructure = useAppStructure();
  const applets = useAppletsList();
  const appEventHub = useAppEventHub();
  const {theme} = useAppTheme();
  const [currentPage, setCurrentPage] = useState<string | undefined>();
  const [isExpanded, setIsExpanded] = useControllableState({
    strategy: 'most-recent-wins',
    prop: undefined,
    defaultProp: false,
  });

  useEffect(
    (() => {
      if (!highlightCurrentPage) return;
      return appEventHub?.addListener(AppEvents.ROUTE, (args) => {
        setCurrentPage(args);
      });
    }) as any,
    [],
  );

  const menuItems = useMenuItems({
    appStructure,
    applets,
    currentPage,
    isExpanded,
  });

  const toggleSidebar = useEvent(() => {
    setIsExpanded((prev) => !prev);
  });

  return (
    <YStack
      className="no-scrollbar"
      flex={1}
      backgroundColor={theme === 'dark' ? '#242526' : '$accent'}
      justifyContent="space-between"
      borderRadius="$14"
      width={isExpanded ? 250 : 60}
      height="100%"
      paddingVertical="$5"
      overflow="scroll"
      paddingHorizontal={isExpanded ? '$1' : '$0'}
      style={{
        transition: 'width 0.3s ease, padding 0.3s ease',
      }}>
      <YStack gap="$4">
        <XStack
          alignItems="center"
          justifyContent={isExpanded ? 'flex-start' : 'center'}
          flex={1}
          gap="$2"
          paddingHorizontal="$4">
          <Pressable
            backgroundColor="$lightWhite"
            alignItems="center"
            width={32}
            height={32}
            borderRadius="$rounded-full"
            justifyContent="center"
            onPress={toggleSidebar}>
            <BidirectionalIcon
              icon={isExpanded ? 'ArrowLeft01Icon' : 'ArrowRight01Icon'}
              size={16}
              color="white"
              type="standard"
            />
          </Pressable>

          {isExpanded && (
            <Typography color="white" fontWeight="600">
              <ShellTrans i18nKey="launcher.sideBarTitle" />
            </Typography>
          )}
        </XStack>
        <YStack marginBottom="$1.5" paddingHorizontal={isExpanded ? '$1' : '$0'}>
          {menuItems}
        </YStack>
      </YStack>
    </YStack>
  );
};
