import React from 'react';

import {shellScopedKey, YStack} from '@medad-sep/core';

import {SettingsItem} from '../../models';

import {ProfileSelector} from './components/ProfileSelector';

const Component = () => {
  return (
    <YStack paddingHorizontal="$8" $sm={{paddingHorizontal: '$4'}}>
      <ProfileSelector />
    </YStack>
  );
};

export const UserProfile: SettingsItem = {
  id: 'user-profile',
  name: shellScopedKey('settings.userProfile.title'),
  Icon: 'Profile02Icon',
  Component,
  public: false,
};
