import React, {ReactNode} from 'react';

import {Accordion, YStack} from '@medad-sep/core';

interface Props {
  title: ReactNode;
  content: ReactNode;
}

export const SettingSection = ({title, content}: Props) => {
  return (
    <YStack marginVertical="$0.5">
      <Accordion sections={[{title, content}]} variant="outlined" />
    </YStack>
  );
};
