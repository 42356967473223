import React from 'react';

import {Platform} from 'react-native';

import {XStack, IconView, View, useIsAuthenticated, useAuth, Button, CoreTrans} from '@medad-sep/core';

import {DarkMode} from '../../screens/Settings/sections/DarkMode';
import {Language} from '../../screens/Settings/sections/Language';
import {Logo} from '../Logo';

import {ProfileOptions} from './components/ProfileOptions';

export const MainHeader = (): JSX.Element => {
  const isMobile = Platform.OS !== 'web';

  const isAuthenticated = useIsAuthenticated();
  const {login} = useAuth();

  return (
    <XStack
      paddingHorizontal="$6"
      paddingVertical="$0"
      marginHorizontal="$1.5"
      paddingTop="$6"
      paddingBottom={isMobile ? '$1' : '$4'}
      $sm={{marginHorizontal: 0, paddingHorizontal: 16, paddingTop: '$1'}}
      justifyContent="space-between"
      alignItems="center"
      height={isMobile ? '$10' : '$8'}>
      <View width="$18" $sm={{width: '$12'}}>
        <Logo />
      </View>
      <XStack alignItems="center" gap={isMobile ? '$2' : '$4'}>
        <XStack gap={isMobile ? '$1.5' : '$4'} alignItems="center">
          {DarkMode.Component}
          {Language.Component}
        </XStack>
        {isAuthenticated ? (
          <XStack alignItems="center" gap={isMobile ? '$3' : '$4'}>
            <Button hierarchy="link-gray" prefix={<IconView size={18} icon="NotificationSquareIcon" />} />

            <ProfileOptions />
          </XStack>
        ) : (
          <Button onPress={() => login()} hierarchy="link-gray" prefix={<IconView icon="LoginSquare02Icon" />}>
            <CoreTrans i18nKey="login" />
          </Button>
        )}
      </XStack>
    </XStack>
  );
};
