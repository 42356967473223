import React from 'react';

import {useNavigation} from '@react-navigation/native';
import {Pressable} from 'react-native';

import {BidirectionalIcon, useAuth, useIsAuthenticated, useIsDemoMode, View, calendarService} from '@medad-sep/core';

export const AuthAction = () => {
  const isDemoMode = useIsDemoMode();
  const isAuthenticated = useIsAuthenticated();
  const {login, logout} = useAuth();
  const navigation = useNavigation();

  const onAction = async () => {
    if (isAuthenticated) {
      logout();
      navigation.navigate('main' as never);
      calendarService.clearEvents();
    } else {
      login();
    }
  };

  return (
    <Pressable onPress={!isDemoMode ? onAction : () => {}}>
      <View
        width={36}
        height={36}
        backgroundColor="white"
        borderRadius={10}
        padding="$0.5"
        justifyContent="center"
        alignItems="center">
        <BidirectionalIcon icon={isAuthenticated ? 'LogoutSquare02Icon' : 'LoginSquare02Icon'} size={16} accent />
      </View>
    </Pressable>
  );
};
