import React from 'react';

import {useNavigation} from '@react-navigation/native';

import {
  useRoutesStack,
  Breadcrumb,
  LocalizedText,
  IconView,
  Pressable,
  navigateToRoot,
  XStack,
  Typography,
} from '@medad-sep/core';

export const BreadrumbNavigator = () => {
  const routesStack = useRoutesStack();
  const navigation = useNavigation();

  return (
    <Breadcrumb
      prefix={
        <Pressable onPress={() => navigateToRoot(navigation as any)}>
          <IconView color={routesStack.length === 1 ? '$accent' : '$gray-600'} icon="Home04Icon" size={17} />
        </Pressable>
      }>
      {routesStack.slice(1)?.map(({title, routeId}, index) => {
        const isLastRoute = routeId === routesStack.slice(1)[routesStack.slice(1).length - 1].routeId;
        return (
          <Pressable key={index} onPress={() => !isLastRoute && navigation.navigate(routeId as never)}>
            <XStack gap="$0.5">
              <Typography fontWeight="500" color="$gray-400" fontSize="$14" marginHorizontal="$0.5">
                /
              </Typography>
              <Typography
                fontWeight="500"
                variant="body2"
                color={index === routesStack.slice(1).length - 1 ? '$accent' : '$gray-600'}>
                <LocalizedText value={title} key={index} />
              </Typography>
            </XStack>
          </Pressable>
        );
      })}
    </Breadcrumb>
  );
};
