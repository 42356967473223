import React from 'react';

import {Button, IconView, shellScopedKey, Theme, useAppTheme} from '@medad-sep/core';

import {SettingsItem} from '../models';
const DarkModeSelector = (): JSX.Element => {
  const {theme, setTheme} = useAppTheme();
  return (
    <Button
      hierarchy="link-gray"
      prefix={<IconView icon={theme === Theme.light ? 'Moon02Icon' : 'Sun03Icon'} />}
      onPress={() => setTheme(theme === Theme.light ? Theme.dark : Theme.light)}
    />
  );
};
export const DarkMode: SettingsItem = {
  id: 'dark-mode',
  name: shellScopedKey('settings.darkMode'),
  Icon: 'Moon02Icon',
  Component: <DarkModeSelector />,
};
