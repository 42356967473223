import React, {useState} from 'react';

import {useNavigation} from '@react-navigation/native';

import {
  IconView,
  useIsAuthenticated,
  useAuth,
  Avatar,
  calendarService,
  useIsDemoMode,
  Select,
  Typography,
  YStack,
  ShellTrans,
  ListItem,
  useAppDirection,
} from '@medad-sep/core';

import {Settings} from '../../../screens/Settings';

export const ProfileOptions = (): JSX.Element => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const {direction} = useAppDirection();
  const isDemoMode = useIsDemoMode();
  const isAuthenticated = useIsAuthenticated();
  const navigation = useNavigation();
  const {logout} = useAuth();

  const onLogout = async () => {
    if (isAuthenticated) {
      logout();
      navigation.navigate('main' as never);
      calendarService.clearEvents();
    }
    setIsSelectOpen(false);
  };

  return (
    <Select
      open={isSelectOpen}
      onOpenChange={setIsSelectOpen}
      trigger={<Avatar size="sm" marginHorizontal={1} />}
      modelHeight="30%">
      <YStack justifyContent="space-between">
        <ListItem
          direction={direction}
          onPress={() => {
            navigation.navigate(Settings.id as never);
            setIsSelectOpen(false);
          }}
          minWidth="$19"
          prefix={<IconView icon="Settings05Icon" size={18} />}
          title={
            <Typography marginHorizontal="$0.5" fontWeight="500">
              <ShellTrans i18nKey="settings.title" />
            </Typography>
          }
        />
        <ListItem
          direction={direction}
          onPress={!isDemoMode ? onLogout : () => {}}
          prefix={<IconView icon="LogoutSquare02Icon" size={18} />}
          minWidth="$19"
          title={
            <Typography marginHorizontal="$0.5" fontWeight="500">
              <ShellTrans i18nKey="settings.logOut" />
            </Typography>
          }
        />
      </YStack>
    </Select>
  );
};
