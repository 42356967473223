import React from 'react';

import {Platform} from 'react-native';

import {Avatar, Image, Typography, XStack, YStack, useCurrentUser, View} from '@medad-sep/core';

export const UserProfileSection = () => {
  // todo: enhance
  return null;

  const currentUser = useCurrentUser();
  const isMobile = Platform.OS !== 'web';

  const imageHeight = 150;
  return (
    <YStack width="100%" height={imageHeight} marginBottom="$9" alignItems="center">
      <View width="100%" paddingTop={16} paddingHorizontal="$6" $xs={{paddingHorizontal: '$4'}}>
        <Image
          source={require('../../../../../assets/settingsBG.jpg')}
          width="100%"
          borderRadius={16}
          height={imageHeight}
        />
      </View>
      <XStack
        width="100%"
        position="relative"
        paddingHorizontal={60}
        bottom="50%"
        zIndex={2}
        $xs={{paddingHorizontal: '$8'}}>
        <Avatar size={isMobile ? 'xl' : '2xl'} />

        <YStack justifyContent="flex-end" marginHorizontal="$4">
          <Typography alignSelf="flex-end" fontWeight="700" fontSize="$20" $xs={{fontSize: '$16'}}>
            {currentUser && currentUser?.name}
          </Typography>
          <Typography height={isMobile ? '$3.5' : '$3'} color="$borderHover" fontSize="$16" $xs={{fontSize: '$14'}}>
            {currentUser && currentUser?.username}
          </Typography>
        </YStack>
      </XStack>
    </YStack>
  );
};
