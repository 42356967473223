import React, {ComponentProps} from 'react';

import {View} from '@medad-sep/core';

export const withPreviewView = (Component) => (props: ComponentProps<typeof Component>) => {
  return (
    <View padding="$1">
      <Component {...props} />
    </View>
  );
};
